<template>


  <div class="guest__page">
    <div class="guest__page--header" >
        <i @click="open_city = true" class="mdi mdi-map-marker"></i>
        <i @click="$router.push('admin-login')" class="mdi mdi-account"></i>
    </div>

    <v-dialog
        max-width="600"
        v-model="open_city">
      <v-card>
        <div class="pa-5 item__column modal__bonus modal__cities">
          <p class="modal__bonus__title modal__cities--title">Выбор города</p>

          <div class="modal__city"
               v-for="(city, city_index) in cities" :key="city_index"
               @click="getCompaniesById(city.id)"
          >
            <p>{{ city.name }} </p>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
        max-width="600"
        v-model="open_barcode">
      <v-card>
        <div class="pa-5 item__column modal__bonus modal__cities">
          <p class="modal__bonus__title modal__cities--title">Выбор города</p>


          <v-text-field
              v-model="name"
              label="Имя"
              required
              outlined
          ></v-text-field>

          <p>Введите Whatsapp номер:</p>
          <masked-input
              class="sms__digits"
              placeholder="8 XXX XXX XX XX"
              v-model.trim="phone"
              mask="8(111) 111-11-11"
              ref="numericInput"
              type="tel"
          />

          <v-btn
              type="submit"
              class="primary"
              @click="saveBarcode"
          >
            Получить код
          </v-btn>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog
        max-width="600"
        v-model="open_code">
      <v-card>
        <div class="pa-5 item__column modal__bonus modal__cities">
          <p class="modal__bonus__title modal__cities--title">Выбор города</p>


          <v-text-field
              v-model="code"
              label="Введите код"
              required
              outlined
          ></v-text-field>

          <v-btn
              type="submit"
              class="primary"
              @click="checkCode"
          >
            Проверить
          </v-btn>

        </div>
      </v-card>
    </v-dialog>


    <div class="guest__page--company-block">
      <h2 class="guest__page--title">Все магазины</h2>
      <div class="guest__page--companies">
        <div
            class="guest__page--company"
            v-for="(company,index) in companies"
            :key="index"
            @click="$router.push('/company/'+company.id)"
        >
          <img v-if="company.photos.length !== 0"  :src="$BACK_URL+'storage/'+company.photos[0].value" alt="">
          <div class="guest__page--detail">

            <p class="guest__page--name">{{ company.name }}</p>

            <div v-if="company.bonus_setting">
              <p class="guest__page--bonus" v-if="company.bonus_setting.type === 'n_bonus'">{{ company.bonus_setting.count_value }}-я бесплатно</p>
              <p class="guest__page--bonus" v-else>{{ company.bonus_setting.bonus_value }}%</p>
            </div>

          </div>

        </div>
      </div>
    </div>



    <h2></h2>
  </div>
</template>


<script>
import MaskedInput from "vue-masked-input";
export default {
  name: "GuestPage",
  components: {
    MaskedInput
  },
  data() {
    return {
      barcode_value: '',
      open_code: false,
      code: '',
      name: '',
      phone: '',
      companies: [],
      cities: [],
      open_city: false,
      open_barcode: false
    }
  },
  beforeMount() {
    this.getCompanies();
    this.getCities();
  },
  mounted() {
    if(localStorage.getItem('code')) {
      this.code = localStorage.getItem('code')
    }
  },
  methods: {
    checkCode() {
      console.log(this.phone)
      let data = {
        phone: this.phone,
        code: this.code
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}guest/code`,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        // },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });


            this.code = res.data.code
            localStorage.setItem('code',res.data.code)

            this.open_code = false

          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    saveBarcode() {
      let data = {
        phone: this.phone,
        name: this.name
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}guest/barcode`,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        // },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            })
            this.open_barcode = false
            this.open_code = true
            // this.getWorkers()
            // this.update_user = false
            // this.email = ''
            // this.name = ''
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    getCities() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}guest/cities`,
      })
          .then((res) => {
            this.cities = res.data
          })
    },
    getCompanies() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}guest/clients?is_client=true`,
      })
      .then((res) => {
        this.companies = res.data
      })
    },
    getCompaniesById(city_id) {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}guest/clients?is_client=true&city_id=${city_id}`,
      })
      .then((res) => {
        this.companies = res.data
        this.open_city = false
      })
    }
  }

};
</script>

<style>

.guest__page {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

}

.guest__page--companies {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.guest__page--company {

  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .guest__page--name {
    font-size: 28px;
    font-weight: bold;
  }
}

img {
  max-width: 100%;
}

.guest__page--info {
  margin-top: 20px;
  width: 95%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 30px;
}



.qr--btn {
  background: #2a79f0;
  padding: 15px 20px;
  color: white;
  border-radius: 5px;
}


.guest__page--company-block {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.guest__page--title {
  margin-bottom: 10px;
  align-self: flex-start;
}

.guest__page--header {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}
.guest__page--header i {
  color: #2a79f0;
}

.guest__page--detail {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.guest__page--bonus {
  position: absolute;
  right: 20px;
  top: 0;
  padding: 8px;
  border-radius: 5px;
  background: #2dba29;
  color: white;
  width: 50px;
  text-align: center;
}


.modal__cities {
  display: flex;
  flex-direction: column;


  .modal__cities--title {
    font-size: 18px;
    font-weight: bold;
    align-self: center;
  }



  .modal__city {
    padding-top: 10px;
    border-bottom: 1px solid black;
  }
}

.sms__digits {
  padding: 10px;
  outline: none;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.guest__code {
  font-size: 24px;
  margin-bottom: 0;
}
</style>